/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SetRoomUserDetailsRequest } from '../../models/set-room-user-details-request';

export interface SetRoomUserDetails$Params {
  roomId: string;
      body: SetRoomUserDetailsRequest
}

export function setRoomUserDetails(http: HttpClient, rootUrl: string, params: SetRoomUserDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, setRoomUserDetails.PATH, 'put');
  if (params) {
    rb.path('roomId', params.roomId, {"style":"simple"});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

setRoomUserDetails.PATH = '/chat/v0/rooms/{roomId}/user-details';
