/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RoomDtoPage } from '../../models/room-dto-page';

export interface ListRooms$Params {
  status?: string;
  assigneeType?: string;
  assigneeId?: string;
  hasMessages?: boolean;
  hasFeedback?: boolean;
  isStarred?: boolean;
  emotions?: Array<string>;
  topics?: Array<string>;
  offset?: number;
  limit?: number;
}

export function listRooms(http: HttpClient, rootUrl: string, params?: ListRooms$Params, context?: HttpContext): Observable<StrictHttpResponse<RoomDtoPage>> {
  const rb = new RequestBuilder(rootUrl, listRooms.PATH, 'get');
  if (params) {
    rb.query('status', params.status, {"style":"form"});
    rb.query('assigneeType', params.assigneeType, {"style":"form"});
    rb.query('assigneeId', params.assigneeId, {"style":"form"});
    rb.query('hasMessages', params.hasMessages, {"style":"form"});
    rb.query('hasFeedback', params.hasFeedback, {"style":"form"});
    rb.query('isStarred', params.isStarred, {"style":"form"});
    rb.query('emotions', params.emotions, {"style":"form"});
    rb.query('topics', params.topics, {"style":"form"});
    rb.query('offset', params.offset, {"style":"form"});
    rb.query('limit', params.limit, {"style":"form"});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RoomDtoPage>;
    })
  );
}

listRooms.PATH = '/chat/v0/rooms';
