/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { closeRoom } from '../fn/rooms/close-room';
import { CloseRoom$Params } from '../fn/rooms/close-room';
import { createRoom } from '../fn/rooms/create-room';
import { CreateRoom$Params } from '../fn/rooms/create-room';
import { enterRoom } from '../fn/rooms/enter-room';
import { EnterRoom$Params } from '../fn/rooms/enter-room';
import { Event } from '../models/event';
import { EventDtoPage } from '../models/event-dto-page';
import { exitRoom } from '../fn/rooms/exit-room';
import { ExitRoom$Params } from '../fn/rooms/exit-room';
import { getRoom } from '../fn/rooms/get-room';
import { GetRoom$Params } from '../fn/rooms/get-room';
import { listEvents } from '../fn/rooms/list-events';
import { ListEvents$Params } from '../fn/rooms/list-events';
import { listRooms } from '../fn/rooms/list-rooms';
import { ListRooms$Params } from '../fn/rooms/list-rooms';
import { markRoomStarred } from '../fn/rooms/mark-room-starred';
import { MarkRoomStarred$Params } from '../fn/rooms/mark-room-starred';
import { postEvent } from '../fn/rooms/post-event';
import { PostEvent$Params } from '../fn/rooms/post-event';
import { Room } from '../models/room';
import { RoomDtoPage } from '../models/room-dto-page';
import { setRoomUserDetails } from '../fn/rooms/set-room-user-details';
import { SetRoomUserDetails$Params } from '../fn/rooms/set-room-user-details';
import { unmarkRoomStarred } from '../fn/rooms/unmark-room-starred';
import { UnmarkRoomStarred$Params } from '../fn/rooms/unmark-room-starred';

@Injectable({ providedIn: 'root' })
export class RoomsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listRooms()` */
  static readonly ListRoomsPath = '/chat/v0/rooms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listRooms()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRooms$Response(params?: ListRooms$Params, context?: HttpContext): Observable<StrictHttpResponse<RoomDtoPage>> {
    return listRooms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listRooms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listRooms(params?: ListRooms$Params, context?: HttpContext): Observable<RoomDtoPage> {
    return this.listRooms$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoomDtoPage>): RoomDtoPage => r.body)
    );
  }

  /** Path part for operation `createRoom()` */
  static readonly CreateRoomPath = '/chat/v0/rooms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRoom()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoom$Response(params: CreateRoom$Params, context?: HttpContext): Observable<StrictHttpResponse<Room>> {
    return createRoom(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRoom$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoom(params: CreateRoom$Params, context?: HttpContext): Observable<Room> {
    return this.createRoom$Response(params, context).pipe(
      map((r: StrictHttpResponse<Room>): Room => r.body)
    );
  }

  /** Path part for operation `exitRoom()` */
  static readonly ExitRoomPath = '/chat/v0/rooms/{roomId}/exit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exitRoom()` instead.
   *
   * This method doesn't expect any request body.
   */
  exitRoom$Response(params: ExitRoom$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return exitRoom(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exitRoom$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exitRoom(params: ExitRoom$Params, context?: HttpContext): Observable<void> {
    return this.exitRoom$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `setRoomUserDetails()` */
  static readonly SetRoomUserDetailsPath = '/chat/v0/rooms/{roomId}/user-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setRoomUserDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setRoomUserDetails$Response(params: SetRoomUserDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setRoomUserDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setRoomUserDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setRoomUserDetails(params: SetRoomUserDetails$Params, context?: HttpContext): Observable<void> {
    return this.setRoomUserDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `closeRoom()` */
  static readonly CloseRoomPath = '/chat/v0/rooms/{roomId}/close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeRoom()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeRoom$Response(params: CloseRoom$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return closeRoom(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `closeRoom$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeRoom(params: CloseRoom$Params, context?: HttpContext): Observable<void> {
    return this.closeRoom$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `listEvents()` */
  static readonly ListEventsPath = '/chat/v0/rooms/{roomId}/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEvents$Response(params: ListEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<EventDtoPage>> {
    return listEvents(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEvents(params: ListEvents$Params, context?: HttpContext): Observable<EventDtoPage> {
    return this.listEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventDtoPage>): EventDtoPage => r.body)
    );
  }

  /** Path part for operation `postEvent()` */
  static readonly PostEventPath = '/chat/v0/rooms/{roomId}/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postEvent$Response(params: PostEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<Event>> {
    return postEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postEvent(params: PostEvent$Params, context?: HttpContext): Observable<Event> {
    return this.postEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Event>): Event => r.body)
    );
  }

  /** Path part for operation `getRoom()` */
  static readonly GetRoomPath = '/chat/v0/rooms/{roomId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoom()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoom$Response(params: GetRoom$Params, context?: HttpContext): Observable<StrictHttpResponse<Room>> {
    return getRoom(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoom$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoom(params: GetRoom$Params, context?: HttpContext): Observable<Room> {
    return this.getRoom$Response(params, context).pipe(
      map((r: StrictHttpResponse<Room>): Room => r.body)
    );
  }

  /** Path part for operation `enterRoom()` */
  static readonly EnterRoomPath = '/chat/v0/rooms/{roomId}/enter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enterRoom()` instead.
   *
   * This method doesn't expect any request body.
   */
  enterRoom$Response(params: EnterRoom$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return enterRoom(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enterRoom$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enterRoom(params: EnterRoom$Params, context?: HttpContext): Observable<void> {
    return this.enterRoom$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `markRoomStarred()` */
  static readonly MarkRoomStarredPath = '/chat/v0/rooms/{roomId}/mark-starred';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markRoomStarred()` instead.
   *
   * This method doesn't expect any request body.
   */
  markRoomStarred$Response(params: MarkRoomStarred$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markRoomStarred(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markRoomStarred$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markRoomStarred(params: MarkRoomStarred$Params, context?: HttpContext): Observable<void> {
    return this.markRoomStarred$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `unmarkRoomStarred()` */
  static readonly UnmarkRoomStarredPath = '/chat/v0/rooms/{roomId}/unmark-starred';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unmarkRoomStarred()` instead.
   *
   * This method doesn't expect any request body.
   */
  unmarkRoomStarred$Response(params: UnmarkRoomStarred$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unmarkRoomStarred(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unmarkRoomStarred$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unmarkRoomStarred(params: UnmarkRoomStarred$Params, context?: HttpContext): Observable<void> {
    return this.unmarkRoomStarred$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
